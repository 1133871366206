//
// Main
//
// Import this file using the following HTML or equivalent:
// <script src="dist/js/main.min.js" type="text/javascript"></script>
//

jQuery( document ).ready( function( $ ) {

    function setColHolderHeight() {
        var col_hold = $(".collection__holder");
        $(col_hold).removeAttr('style');
        if ($(window).width() > 479) {
            var chh = $(col_hold).outerHeight();
            $(col_hold).css({"height": chh + "px"});
        }
    }

    setColHolderHeight();

    if ( typeof $.mmenu === 'function' ) {

        $("#nav").mmenu({
          "offCanvas": {
            "position": "right"
          }
        }, {
          // configuration
          clone: true
        });

        }

        $('.products .products__more a').on("click", function(event){
        event.preventDefault();

        $('.products ul.hidden').slideToggle('fast');
        $(this).toggleClass('active');

    });

    var vsh = true;
    $('.slider .slider__icons a.icon').on("click", function(event){
        event.preventDefault();

        var sh = $(".slider .slider__holder");
        if (vsh)
        {
          var shh = $(sh).outerHeight();
          $(sh).css({"margin-bottom":"-"+shh+"px"});
        }
        else
        {
          $(sh).css({"margin-bottom":"0px"});
        }

        vsh = !vsh;
        // We can't use slideToggle here as the slider will nullify the height
        // when there is a transition
        //$('.slider .slider__holder').slideToggle('fast');
    });

    setTimeout(function(){

        $('.collection__col, .collection__watch__col').viewportChecker({
            // Class to add to the elements when they are visible
            classToAdd: 'visible',

            // The offset of the elements (let them appear earlier or later)
            offset: 100,

            // Add the possibility to remove the class if the elements are not visible
            repeat: false
        });
    }, 600);
    setTimeout(function(){

        $('.banner h1').viewportChecker({
            // Class to add to the elements when they are visible
            classToAdd: 'visible',

            // The offset of the elements (let them appear earlier or later)
            offset: 100,

            // Add the possibility to remove the class if the elements are not visible
            repeat: false
        });
    }, 300);

    $('li.languages>a').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        $(this).parent().find('ul').stop().slideToggle();
    });
    $('a.search').click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('a.search').toggleClass('activesearch-button');

        if($('.search-holder').hasClass('activesearch')) {

            $('.search-holder').removeClass('activesearch');

            $('body').css({
                'overflow': 'visible'
            });

        } else {

            $('.search-holder').addClass('activesearch');

            $('body').css({
                'overflow': 'hidden'
            });

        }

    });
    $('.slider-holder').slick({
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: false,
        pauseOnFocus: false,
        fade: true,
        dots: false
    });
    $height = $('.header').outerHeight();
    $footerheight = $('.footer').outerHeight();
    //$('.wrapper').css('padding-top', $height);
    if ($(window).outerWidth() > 479) {
        $('.wrapper').css('padding-bottom', $footerheight);
    }

    $(window).resize(function(){
        $height = $('.header').outerHeight();
        $footerheight = $('.footer').outerHeight();
      //  $('.wrapper').css('padding-top', $height);
        $('.wrapper').css('padding-bottom', $footerheight);
        setColHolderHeight();
    });


    $('.parallax-window').parallaxScroll({
        friction: 0.2
    });

	$('.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		infinite: true,
		fade: true,
		asNavFor: '.slider-nav'
	});

	$('.slider-nav').slick({
		slidesToShow: 6,
		slidesToScroll: 1,
		asNavFor: '.slider-for',
		dots: false,
		arrows: false,
		infinite: true,
		centerMode: false,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 5
				}
			},
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]
	});

	$('.details__slider ul').slick({
		adaptiveHeight: true,
		slidesToScroll: 1,
		autoplay: true,
		arrows: false,
		dots: true
	});
    $('.slick-arrow').click(function() {
        $('.slider__thumbs--projects .more-less .less').hide();
        $('.slider__thumbs--projects .more-less .more').show();
        $('.project-info').stop().fadeOut(300);
        $('.project-info-overlay').stop().fadeOut(300);
    });
	$('.slider__slide').each(function() {
		var $this = $(this);
        $(this).find('.slider__thumbs--projects .more-less .more').click(function(event){
            event.preventDefault();
            $this.find('.project-info').stop().fadeIn(300);
            $this.find('.project-info-overlay').stop().fadeIn(300);
            $('.slider__thumbs--projects .more-less .more').hide();
            $('.slider__thumbs--projects .more-less .less').show();
        });
        $(this).find('.slider__thumbs--projects .more-less .less').click(function(event){
            event.preventDefault();
            $this.find('.project-info').stop().fadeOut(300);
            $this.find('.project-info-overlay').stop().fadeOut(300);
            $('.slider__thumbs--projects .more-less .less').hide();
            $('.slider__thumbs--projects .more-less .more').show();
        });
	});
    $window = $(window).outerHeight();
    //$('.wrapper').css('min-height', $window); // TODO: Disabled until a fix is in
    if ($(window).outerWidth() < 480) {
        $('.ebook').css('height',$window - $height);
    }
    else {
        $('.ebook').css('height', $window - $height - $footerheight);
    }
    $(window).resize(function(){
        $window = $(window).outerHeight();
        //$('.wrapper').css('min-height', $window);
        if ($window < 480) {
            $('.ebook').css('height',$window - $height);
        }
        else {
            $('.ebook').css('height', $window - $height - $footerheight);
        }
    });
	$('.project-info__close, .project-info-overlay').click(function(){
		$('.project-info').fadeOut(300);
		$('.project-info-overlay').fadeOut(300);
        $('.slider__thumbs--projects .more-less .less').hide();
        $('.slider__thumbs--projects .more-less .more').show();
	});

	$('.slider-for').on('afterChange', function(event, slick, currentSlide, nextSlide){
	  $('.project-info').fadeOut();
	  $('.project-info-overlay').fadeOut();
	});

	var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) {
                continue;
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if(index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe( index, clickedGallery );
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
        var hash = window.location.hash.substring(1),
        params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if(pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
            }

        };

        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};

// execute above function
initPhotoSwipeFromDOM('.my-gallery');

$('.details__share .share-icon').click(function(){
    $(this).parent().toggleClass('active');
});


if ($('#map').length > 0) {

/* ========================================================================
 GOOGLE MAPS
* ======================================================================== */
    var map,
        lat      = $('.banner--map').attr('data-lat'),
        lng      = $('.banner--map').attr('data-lng'),
        location = new google.maps.LatLng(lat, lng),
        drag;

    if ($(window).innerWidth() > 767) {
        drag = true;
    } else {
        drag = false;
    }


    function initialize() {
        var mapOptions = {
            zoom: 10,
            center: location,
            scrollwheel: false,
            draggable: drag,
            disableDefaultUI: true,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
            }
        };

        // init MAP
        map = new google.maps.Map(document.getElementById('map'), mapOptions);

        var icon = {
            scaledSize: new google.maps.Size(40, 40),
            url:        '/wp-content/themes/forzalaqua/dist/img/marker.svg',
            origin:     new google.maps.Point(0,0),
            anchor:     new google.maps.Point(0,0)
        };

        // init MARKER
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map:  map,
            icon: icon
        });
    }

    initialize();

    var getCen = map.getCenter();

    google.maps.event.addDomListener(window, 'resize', function() {
        map.setCenter(getCen);
    });

    }



} );
